<template>
  <v-app :style="fontStyle">
    <v-main class="accent">
      <router-view />

      <toast-messages />
    </v-main>
  </v-app>
</template>

<script>
const ToastMessages = () => import(/* webpackChunkName: "toast-messages" */ "@/components/common/ToastMessages.vue")

export default {
  name: "EmptyTemplate",

  components: {
    ToastMessages
  },
}
</script>